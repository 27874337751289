import React, { useEffect } from 'react'
import { getProfileFromId } from '../../utils/api'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import clubRegisterActions from '../../state/club-register/actions'
import moment from 'moment'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import Cookies from 'universal-cookie'
const cookies = new Cookies()
const PageInvite = props => {
  const handleRedirectPage = async () => {
    const querystring = queryString.parse(window.location.search)
    const { clubname, user_id } = querystring
    const userData = await getProfileFromId(user_id)
    if (userData && userData.data && userData.data._id === user_id) {
      cookies.set('_id', userData.data._id, {
        path: '/',
        expires: new Date(
          moment()
            .add(1, 'day')
            .format()
        ),
      })
      props.onSetProfile({
        ...userData.data,
        user_of: clubname,
      })
      navigate(`my-club/register`)
      return
    }
    navigate('/club/KMITLEngineerAlumni')
    return
  }

  useEffect(() => {
    handleRedirectPage()
  }, [])
  return <div>Redirecting...</div>
}
const mapStateToProps = state => {
  return {
    state: state.home,
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      onSetProfile: clubRegisterActions.onSetProfile,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageInvite)

// class PageInvite extends Component {
//   constructor(props) {
//     super(props)
//   }
//   componentDidMount() {
//     console.log('this', this.props)
//     this.handleRedirectPage()
//   }
//   handleRedirectPage = async () => {
//     const querystring = queryString.parse(window.location.search)
//     const { clubname, user_id } = querystring
//     console.log('test')
//     return
//     // const userData = await getProfileFromId(user_id)
//     // if (userData && userData.data && userData.data._id === user_id) {
//     //   cookies.set('_id', userData.data._id, {
//     //     path: '/',
//     //     expires: new Date(
//     //       moment()
//     //         .add(1, 'day')
//     //         .format()
//     //     ),
//     //   })
//     //   this.props.onSetProfile({
//     //     ...userData.data,
//     //     user_of: clubname,
//     //   })
//     //   navigate(`my-club/register`)
//     //   return
//     // }
//     // navigate('/club/KMITLEngineerAlumni')
//     // return
//   }
//   render() {
//     return <div>Redirecting...</div>
//   }
// }
// const mapStateToProps = state => {
//   return {
//     state: state.home,
//   }
// }
// const mapDispatchToProps = dispatch => {
//   return bindActionCreators(
//     {
//       onSetProfile: clubRegisterActions.onSetProfile,
//     },
//     dispatch
//   )
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(PageInvite)
